/* src/components/Header.css */
.header {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
  }
  
  .header-title {
    margin: 0;
    font-size: 2.5em;
  }
  
  .header-subtitle {
    margin: 10px 0 0 0;
    font-size: 1.2em;
    color: #555;
  }
  
/* src/components/Footer.css */
.footer {
    background-color: #f5f5f5;
    text-align: center;
    padding: 15px;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  .footer-text {
    margin: 0;
    color: #555;
  }
  
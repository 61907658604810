/* src/components/Section.css */
.section {
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .section-title {
    font-size: 1.8em;
    margin-bottom: 10px;
  }
  
  .section-description {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .section-list {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .section-list-item {
    margin-bottom: 5px;
  }
  